import React from "react";
import ReactDOM from "react-dom";
import './index.css';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Landing } from "views/Landing";
import { Terms } from "views/Terms";
import { Privacy } from "views/Privacy";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/" component={Terms} />
      <Redirect to="/" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
